import * as React from "react"
import { Card, Stack } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../components/social.scss"

const text = `Hi! My name's Alessio aka Bolo. I'm from Ferrara (Italy) where I was
born in 1978, where I live and create, and I'm a member of the
artistic collective Vida Krei (VKB). I've been used to(badly)
sketching since I was a little child but, after some years of
inactivity due to my studies in electronic engineering and to my job
as a 3d graphic designer, I started to (try to) paint in 2008. In
2010 I won the first awards and made my first solo show. I've always
used to get bored, so I constantly need to evolve my language and
explore new subjects. I run the risk to not be appreciated but I
can’t avoid to renew. So, after I created my alter-ego character
“Sfiggy” through which I told about myself, my story and my
thoughts, I approached to new projects with a wider sensibility. I'm
naturally curious and always open to confrontation and contamination
of cultures and styles and that impacts on both my fine-art works
(that I show in the galleries) and on the murals I paint everywhere
in Italy and other countries. I'm today represented by galleries in
Italy, USA and Portugal, I paint at Street Art Festivals and events
all around the world but I still believe that grabbing my stuff and
spending a day painting on a wall with friends is one of the
funniest things in the world.`

const ArtistPage = () => (
  <Layout>
    <Seo
      title={"Artist"}
      imageURL={"/fotoprofilo.jpg"}
      description={text.substring(0, 162) + "..."}
    />
    <Stack gap={5}>
      <Card>
        <Card.Img variant="top" src="/fotoprofilo.jpg" />
        <Card.Header as="h5">Alessio Bolognesi</Card.Header>
        <Card.Body>
          <Card.Text>{text}</Card.Text>
          <ul className="social-icons">
            <li>
              <a href="https://www.alessiobolognesi.com/" target="_blank">
                <i className="fa fa-globe"></i>
              </a>
            </li>
            <li>
              <a href="https://facebook.com/sfiggy" target="_blank">
                <i className="fa fa-facebook-official"></i>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/hellamasta" target="_blank">
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/alessio_bolo_bolognesi/"
                target="_blank"
              >
                <i className="fa fa-instagram"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/AlessioBolognesi"
                target="_blank"
              >
                <i className="fa fa-youtube-play"></i>
              </a>
            </li>
            <li>
              <a href="https://t.me/bolourbanartIT" target="_blank">
                <i className="fa fa-telegram"></i>
              </a>
            </li>
          </ul>
        </Card.Body>
      </Card>
    </Stack>
  </Layout>
)

export default ArtistPage
